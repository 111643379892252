/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React from 'react';
import { ScrollArea, useMantineColorScheme } from '@mantine/core';
import LineCharts from '../../pages/Listing/companyData/LineChart';
import styles from './styles.module.css';
import BarCharts from '../../pages/Listing/companyData/BarChart';
import { assetPrefix, renderNumber } from '../../utilities/general';
import useSorting from '../CommonFunction/useSorting';
// import { renderNumber } from '../../utilities/general';

const TraderExpandList = ({ data }) => {
  // eslint-disable-next-line no-unused-vars
  const {
    winRateOneMonthsData, winRateOneMonth, walletData, pnlOneMonth, pnlOneMonthData,
  } = data;
  const { items } = useSorting(walletData, 'totalValueUsd');
  const { colorScheme } = useMantineColorScheme();

  return (
    <>
      <div className={`${colorScheme === 'dark' ? styles.darkContainer : styles.container} flex items-center p-6 gap-5 w-full`}>
        <div className={styles.chartContainer}>
          <LineCharts
            data={pnlOneMonthData}
            chartName="Profit / Loss"
            summury={pnlOneMonth.total}
            changeInPercentage={pnlOneMonth?.changeInPercentage}
            days={30}
            loading="FINISHED"
          />
        </div>
        <div className={`${styles.chartContainer} !pt-0`}>
          <BarCharts
            data={winRateOneMonthsData}
            chartName="Win Rate "
            summury={winRateOneMonth.average}
            days="Last 30 Days"
            color={['#0093EF', '#E9EDF7']}
            loading="FINISHED"
            Listing
          />
        </div>
        <div className={`${styles.hotColdWallet} px-2.5 py-4`}>
          <div className="!font-semibold text-[#3A3A3A]">Wallet</div>
          <div className="flex justify-between pr-2 items-center gap-5 w-full !text-[#98B2D3] !text-xs pt-3 cursor-pointer">
            <div className="text-left">
              <div>
                Asset
              </div>
            </div>
            <div className="text-right">
              <div>
                Value
              </div>
            </div>
          </div>
          <ScrollArea style={{ height: '80%' }} type="never">
            <div className="w-full pt-4">
              <div className="flex flex-col gap-3">
                {items?.map((asset, index) => (
                  <div key={index} className={`flex justify-between items-center ${index !== walletData?.length - 1 ? 'border-b border-[#CECECE] pb-3' : ''}`}>
                    <div className="flex gap-1">
                      <div>
                        <img
                          src={asset?.icon ? asset?.icon : `${assetPrefix}/images/coinBase.svg`}
                          alt="CoinBase"
                          className="max-w-fit w-4 h-4"
                        />
                      </div>
                      <div>
                        {asset?.asset ? asset?.asset : '-'}
                      </div>
                    </div>
                    <div className="!font-normal pr-2.5">{renderNumber(asset?.totalValueUsd, true)}</div>
                  </div>
                ))}
              </div>
            </div>
          </ScrollArea>

        </div>

        {/* {data.length !== 0
          ? (
            <div className="w-full relative">
              <div>
                <div className="flex items-center justify-end text-[#3A3A3A] text-lg mt-8 ml-auto mr-[7%]">
                  <div className="text-sm font-normal text-[#898989] mx-5 py-1 px-1 bg-[#FFFFFF] text-center w-[5rem] rounded-md"><span>Last 7 Days</span></div>
                  <div className={`${styles.traderRowGrid} flex grid w-[70.2%]`}>

                    <div>
                      {renderNumber(data.weeklyData.equity, true)}
                    </div>
                    <div>
                      {`${renderNumber(data.weeklyData.returnRate)}%`}
                    </div>
                    <div>
                      {`${renderNumber(data.weeklyData.winRate)}%`}
                    </div>
                    <div>
                      {renderNumber(data.weeklyData.totalPortfolio, true)}
                    </div>
                    <div className={`${data.weeklyData.pNl > 0 ? 'text-green-500' : 'text-red-500'}`}>
                      {renderNumber(data.weeklyData.pNl, true)}
                    </div>
                    <div>
                      {renderNumber(data.weeklyData.commission, true)}
                    </div>

                  </div>
                </div>
                <div className="flex items-center justify-end text-[#3A3A3A] text-lg pt-6 pb-8 ml-auto mr-[7%]">
                  <div className="text-sm font-normal text-[#898989] mx-5 bg-[#FFFFFF] text-center w-[5rem] py-1 px-1 rounded-md"><span>1 Month</span></div>
                  <div className={`${styles.traderRowGrid} flex grid w-[70.2%]`}>
                    <div>
                      {renderNumber(data.monthlyData.equity, true)}
                    </div>
                    <div>
                      {`${renderNumber(data.monthlyData.returnRate)}%`}
                    </div>
                    <div>
                      {`${renderNumber(data.monthlyData.winRate)}%`}
                    </div>
                    <div>
                      {renderNumber(data.monthlyData.totalPortfolio, true)}
                    </div>
                    <div className={`${data.monthlyData.pNl > 0 ? 'text-green-500' : 'text-red-500'}`}>
                      {renderNumber(data.monthlyData.pNl, true)}
                    </div>
                    <div>
                      {renderNumber(data.monthlyData.commission, true)}
                    </div>
                  </div>

                </div>
              </div>
              <div />

            </div>
          )
          : <p className="text-center font-bold">No Data</p>} */}
      </div>
    </>
  );
};

export default TraderExpandList;
