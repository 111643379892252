/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Cell, Label, Legend, Pie, PieChart, ResponsiveContainer,
} from 'recharts';
import styles from '../CompanyDataV2.module.css';
import { renderNumber } from '../../../../utilities/general';

export default function ProfitCard({ data }) {
  const chartData = [
    { name: 'empty', value: 100 - (data?.winRate * 100) },
    { name: 'Win Rate', value: data?.winRate * 100 },
  ];

  const returnChartData = [
    { name: 'empty', value: 100 - (data?.returnOnInvestment * 100) },
    { name: 'Return Rate', value: data?.returnOnInvestment * 100 },
  ];

  const COLORS = ['#33B1FF1A', '#0093EF', '#FFFFFF00'];
  const COLORS2 = ['#F9A61A1A', '#F9A61A', '#FFFFFF00'];

  const renderLegend = (item) => {
    const { payload } = item;
    return (
      <>
        {
          payload?.map((entry, index) => (
            (index === 1 || index === 3) && (
              <div className="flex items-center gap-1.5">
                <span
                  style={{
                    backgroundColor: entry?.color,
                  }}
                  className="h-3.5 w-3.5 rounded-[3px]"
                />
                <div key={`item-${index}`} className="flex font-normal not-italic text-sm gap-2">
                  <span className="text-width">{entry?.value}</span>
                  <span style={{ color: entry?.color }}>
                    {renderNumber(entry?.payload?.value)}
                    %
                  </span>
                </div>
              </div>
            )
          ))
        }
      </>
    );
  };

  return (
    <div>
      <div className={`${styles.HeadingText} flex justify-center py-3`}>Lifetime Profit Rates</div>
      <ResponsiveContainer width="99%" height={150}>
        <PieChart>
          <Label value="value" position="center" />
          <Pie
            data={chartData}
            dataKey="value"
            startAngle={90}
            endAngle={450}
            cx="50%"
            cy="50%"
            cornerRadius={10}
            innerRadius={43}
            outerRadius={53}
            fill="#8884d8"
            labelLine={false}
            background={{ fill: '#eee' }}
            paddingAngle={-5}
          >
            {chartData?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Pie
            data={returnChartData}
            dataKey="value"
            startAngle={90}
            endAngle={450}
            cx="50%"
            cy="50%"
            cornerRadius={10}
            innerRadius={25}
            outerRadius={35}
            fill="#8884d8"
            labelLine={false}
            background={{ fill: '#eee' }}
            paddingAngle={-5}
          >
            {returnChartData?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS2.length]} />
            ))}
          </Pie>
          <Legend
            wrapperStyle={{
              top: '9.6rem',
              display: 'grid',
              justifyContent: 'center',
            }}
            iconSize={10}
            content={renderLegend}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
