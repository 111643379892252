import React from 'react';
import { ScrollArea } from '@mantine/core';
import styles from '../CompanyDataV2.module.css';
import { assetPrefix, renderNumber } from '../../../../utilities/general';

export default function InvestmentListing({ data }) {
  return (
    <div className="py-3 h-full">
      <div className={`${styles.HeadingText} px-5`}>Staking Table</div>
      <div className={`${styles.HotColdListingHeader} pt-5 px-5`}>
        <div className={`grid inline items-start transition-transform cursor-pointer text-right ${styles.InvestmentHeadingGrid} ${styles.HotColdHeading}`}>
          <div className="!text-left">Asset</div>
          <div>
            Staking
            <br />
            Balance
          </div>
          <div>
            Pending
            <br />
            Rewards
          </div>
          <div>
            Portfolio
            <br />
            Percentage
          </div>
        </div>
      </div>
      <ScrollArea style={{ height: '60%' }} type="hover" scrollbarSize={5} scrollHideDelay={200}>
        <div className={`${styles.rowContainerMain}`}>
          {data?.map((val) => (
            <div className={`${styles.rowContainer} pt-2`}>
              <div className={`grid inline items-start transition-transform px-5 text-right ${styles.InvestmentRowGrid} ${styles.rowText}`}>
                <div className="flex gap-1">
                  <div>
                    <img
                      src={val?.icon ? val?.icon : `${assetPrefix}/images/coinBase.svg`}
                      alt="CoinBase"
                      className="max-w-fit w-4 h-4"
                    />
                  </div>
                  <div>
                    {val?.asset}
                  </div>
                </div>
                <div className="!font-normal">{renderNumber(val.stakingBalance, true)}</div>
                <div className="!font-normal gap-2">
                  <span>{`${renderNumber(val.pendingBalance)}`}</span>
                  <span>{val?.pendingBalanceType ? val.pendingBalanceType : '-'}</span>
                </div>
                <div className="!font-normal text-[#026DB0]">{`${renderNumber(val.portfolioPercentage)}%`}</div>
              </div>
            </div>
          ))}
        </div>
      </ScrollArea>
    </div>

  );
}
