/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
import { format, isValid, parse } from 'date-fns';
import React, { useEffect, useState } from 'react';
import {
  Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import { useMantineColorScheme } from '@mantine/core';
import { renderNumber } from '../../../utilities/general';
import styles from './CompanyDataV2.module.css';

export default function BarCharts(props) {
  const {
    data, chartName, average, days, color, Listing,
  } = props;
  const { colorScheme } = useMantineColorScheme();
  const [focusBar, setFocusBar] = useState(null);
  const [cordinate, setCordinate] = useState(null);

  const onMouseMove = (state) => {
    // setActiveIndex(state?.activeLabel);
    // setActiveValue(state?.activePayload[0]?.value);
    if (state) {
      setFocusBar(state?.activeTooltipIndex);
    } else {
      setFocusBar(null);
    }
  };
  const onMouseLeave = () => {
    setFocusBar(null);
  };

  useEffect(() => {
    const handleMouseLeave = () => {
      setFocusBar(null);
    };

    document.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  data?.sort((a, b) => new Date(a.date) - new Date(b.date));
  // let highestValue = -Infinity;
  // const lastIndex = (
  //   data
  //   && data.length > 0
  //   && (data[data.length - 1]?.date === activeIndex
  //     ? data[data.length - 1]?.date
  //     : data[data.length - 2]?.date)
  // );
  // if (data) {
  //   for (const item of data) {
  //     if (item.value > highestValue) {
  //       highestValue = item?.value;
  //     }
  //   }
  // }
  const DateFormatter = (date) => {
    if (days === '30 Days') {
      const result = format(new Date(date), 'MMM dd');
      return result || null;
    }
    const d = parse(date, 'M yyyy', new Date());
    return isValid(d) ? format(d, 'LLL') : null;
  };

  const CustomTooltip = (propsss) => {
    const { active, payload, coordinate } = propsss;
    setCordinate(coordinate.x);
    if (active && payload && payload.length) {
      return (
        <div className="p-3 text-sm opacity-100 w-[100px] flex justify-center">
          {payload?.map((entry) => (
            <div style={{ color: color[0] }}>{`${renderNumber(entry?.value)}%`}</div>
          ))}
        </div>
      );
    }
    return null;
  };
  return (
    <div className={`${styles.paddingLeft19} grid py-3 px-6 h-full`}>
      <div className={`${Listing ? 'pt-0' : 'pt-5'} w-[25%]`}>
        <div className={`${styles.HeadingText} flex items-baseline gap-1`}>
          {`${chartName}`}
          <span className="text-xs opacity-70 flex !items-center">
            {` - ${days}`}
          </span>
        </div>
        <div className="flex flex-col">
          <span className={`text-2xl font-bold ${colorScheme === 'dark' ? 'text-[#beb9b9]' : 'text-[#3A3A3A]'}`}>{`${renderNumber(average)}%`}</span>
        </div>
      </div>
      <div className="w-[70%] ml-auto top-[-39px] relative h-full">
        {data
          && (
            <ResponsiveContainer width="99%" height={180}>
              <BarChart
                width={300}
                height={180}
                data={data}
                margin={{
                  top: 10, right: 0, left: 0, bottom: Listing ? 10 : 30,
                }}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}
              >
                <YAxis
                  tickFormatter={(tick) => `${tick}h`}
                  display="none"
                />
                <XAxis
                  dataKey="date"
                  tickLine={false}
                  tickFormatter={DateFormatter}
                  tickSize={12}
                  interval={0}
                  style={{ fontSize: 10 }}
                  axisLine={false}
                  stroke="#A3AED0"
                />
                {/* <ReferenceLine
              label={{
                position: 'right',
                value: renderNumber(activeValue, true),
                fill: color[0],
                fontSize: 12,
              }}
              stroke={color[0]}
              strokeDasharray="3 3"
              segment={[
                { x: activeIndex === lastIndex ? data && data[data?.length - 2]?.date : activeIndex, y: highestValue },
                { x: lastIndex === activeIndex ? data && data[data?.length - 2]?.date : lastIndex, y: highestValue },
              ]}
            /> */}
                <Tooltip
                  dataKey="value"
                  wrapperStyle={{
                    // border: 'none',
                  }}
                  content={<CustomTooltip />}
                  position={{ y: -20, x: cordinate - 50 }}
                  cursor={{ fill: 'none' }}
                />
                <Bar radius={5} type="monotone" dataKey="value" maxBarSize={24} dot={false} fill="#0093EF">
                  {data?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      cursor="pointer"
                      fill={focusBar === index ? color[0] : colorScheme === 'dark' && color[1] === '#E9EDF7' ? '#999fb7' : color[1]}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}
      </div>
    </div>
  );
}
