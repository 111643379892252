import React from 'react';
import { ScrollArea } from '@mantine/core';
import styles from '../CompanyDataV2.module.css';
import { assetPrefix, renderNumber } from '../../../../utilities/general';
import useSorting from '../../../../components/CommonFunction/useSorting';

export default function HotColdWallet({ data }) {
  const { items } = useSorting(data, 'totalValueUsd');

  return (
    <div className="py-3 h-full">
      <div className={`${styles.HeadingText} px-5`}>Wallet</div>
      <div className={`${styles.HotColdListingHeader} pt-5 px-5`}>
        <div className={`grid inline items-start transition-transform cursor-pointer text-right ${styles.HotColdHeadingGrid} ${styles.HotColdHeading}`}>
          <div className="text-left">Asset</div>
          <div>Price</div>
          <div>Amount</div>
          <div>Value</div>
        </div>
      </div>
      <ScrollArea style={{ height: '65%', top: 5 }} type="hover" scrollbarSize={5} scrollHideDelay={200}>
        <div className={`${styles.rowContainerMain} pt-3`}>
          {items?.map((val) => (
            <div className={`${styles.rowContainer} pt-2`}>
              <div className={`grid items-start transition-transform px-5 text-right ${styles.HotColdrowGrid} ${styles.rowText}`}>
                <div className="flex gap-1">
                  <div>
                    <img
                      src={val?.icon ? val?.icon : `${assetPrefix}/images/coinBase.svg`}
                      alt="CoinBase"
                      className="max-w-fit w-4 h-4"
                    />
                  </div>
                  <div>
                    {val.asset}
                  </div>
                </div>
                <div className="!font-normal">{renderNumber(val.currentPriceUsd, true)}</div>
                <div className="!font-normal">{renderNumber(val?.quantity)}</div>
                <div className="!font-normal">{renderNumber(val.totalValueUsd, true)}</div>

              </div>
            </div>

          ))}
        </div>
      </ScrollArea>
    </div>

  );
}
