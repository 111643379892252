import React from 'react';
import { string } from 'prop-types';

import { useMantineColorScheme } from '@mantine/core';
import styles from './TitleWithIndicator.module.css';

const TitleWithIndicator = (props) => {
  const {
    title, subtitle,
  } = props;
  const { colorScheme } = useMantineColorScheme();

  return (
    <div className="flex flex-col ml-5 items-start">
      <div className="flex items-center justify-center">
        <div className="flex flex-row items-center">
          <span className={`${styles.title} ${colorScheme === 'dark' && '!text-[#beb9b9]'} capitalization`}>{title}</span>

        </div>
      </div>
      {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
    </div>
  );
};

TitleWithIndicator.defaultProps = {
  subtitle: '',
};

TitleWithIndicator.propTypes = {
  title: string.isRequired,
  subtitle: string,

};

export default TitleWithIndicator;
