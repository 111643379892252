/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { ChevronDown, X } from 'tabler-icons-react';
import { ActionIcon, useMantineColorScheme } from '@mantine/core';
import commonStyle from '../ListingCommonStyle.module.css';
import TitleWithIndicator from '../../TitleWithIndicator/TitleWithIndicator';
import { buttonize, renderNumber } from '../../../utilities/general';
import TraderExpandList from '../../ExpendedList/TraderExpandList';
// import tradeData from '../../ExpendedList/traderExpandDummy';
import RoundIcon from '../../RoundIcon/RoundIcon';

const TraderListingRow = (props) => {
  const {
    data, dataId,
  } = props;
  const [selectedId, setSelectedId] = useState(0);
  const [open, setOpen] = useState(dataId === 0);
  const navigate = useNavigate();
  const { colorScheme } = useMantineColorScheme();

  function expandList(id) {
    if (id !== null) {
      setSelectedId(id);
      setOpen(!open);
    }
  }
  return (
    <div className={`relative ${colorScheme === 'dark' ? commonStyle.darkTraderList : `${commonStyle.TraderList} ${commonStyle.shadowStyle}`}`}>
      <div
        style={{
          cursor: 'pointer',
        }}
        {...buttonize((e) => {
          e.stopPropagation();
          navigate(`/traders-details/${data.accountId}`);
        })}
        className={`grid items-center p-[1.45rem] ${commonStyle.dataRow} ${commonStyle.traderRowGrid}`}
      >
        <div className="flex items-center">
          <RoundIcon src={data.picture} />
          <TitleWithIndicator
            title={data.name}
            subtitle={data.accountId}
          />
        </div>

        <div className={commonStyle.tableCell}>
          <span>
            {renderNumber(data.lifetimeData.equity, true)}
          </span>
        </div>
        <div className={commonStyle.tableCell}>
          <span>
            {`${renderNumber(data.lifetimeData.lifetimeReturnRate)}%`}
          </span>
        </div>
        <div className={commonStyle.tableCell}>
          <span>
            {`${renderNumber(data.lifetimeData.lifetimeWinRate)}%`}
          </span>
        </div>
        <div className={commonStyle.tableCell}>
          <span>
            {renderNumber(data.lifetimeData.totalPortfolio, true)}
          </span>
        </div>
        <div className={`${data.lifetimeData.lifetimeRealizedPnL > 0 ? 'text-green-500' : 'text-red-500'}`}>
          <span>
            {renderNumber(data.lifetimeData.lifetimeRealizedPnL, true)}
          </span>
        </div>

        <div>
          <span>
            {renderNumber(data.lifetimeData.commission, true)}
          </span>
        </div>
        <ActionIcon {...buttonize((e) => {
          e.stopPropagation();
          expandList(dataId, data);
        })}
        >
          {!open
            ? <ChevronDown />
            : <X />}
        </ActionIcon>
      </div>
      <div>
        {(dataId === selectedId)
          && open
          && (
            <TraderExpandList
              data={data}
            />
          )}
      </div>
    </div>
  );
};

export default TraderListingRow;
