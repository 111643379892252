import React from 'react';
import {
  string,
} from 'prop-types';

import { useMantineColorScheme } from '@mantine/core';
import styles from './HeaderCell.module.css';

const HeaderCell = (props) => {
  const { text, classes } = props;
  const { colorScheme } = useMantineColorScheme();
  return (
    <div className={`flex items-center ${classes} ${colorScheme === 'dark' ? 'text-[#beb9b9]' : 'text-[#000000]'}`}>
      <span className={styles.headerText}>{text}</span>
    </div>
  );
};

HeaderCell.defaultProps = {
  classes: '',

};
HeaderCell.propTypes = {
  text: string.isRequired,
  classes: string,

};

export default HeaderCell;
