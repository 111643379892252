import React from 'react';
import styles from '../ListingCommonStyle.module.css';
import HeaderCell from '../HeaderCell';

const TraderListingHeader = () => (
  <>
    <div className={`${styles.grid6cols} ${styles.rowContainer} ${styles.headerRow}`}>
      <HeaderCell
        classes={styles.companyName}
        text="Traders"
      />
      <HeaderCell
        text="Equity"
      />
      <HeaderCell
        text="Return Rate"
      />
      {' '}
      <HeaderCell
        text="Win Rate"
      />
      <HeaderCell
        text="Total Portfolio Size"
      />
      <HeaderCell
        text="Profit/Loss"
      />
      <HeaderCell
        text="Commission"
      />
      <div className={styles.starColumn} />
    </div>
    {/* <div className={`${styles.rowContainer}
     ${styles.TraderHeaderSubGrid} ${styles.headerRow} pt-0 mb-0`}>
      <span>
        Account ID
      </span>
      <span>
        Total
      </span>
    </div> */}
  </>
);

export default TraderListingHeader;
