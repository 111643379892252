/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { useMantineColorScheme } from '@mantine/core';
import styles from '../CompanyDataV2.module.css';
import { loadingStates, renderNumber } from '../../../../utilities/general';
import { apiWrapWithErrorWithData } from '../../../../utilities/apis/apiHelpers';
import { V2companyBookAnalyticsApi, V2companyBookChartsApi } from '../../../../utilities/apis/companyData';
import ProfitCard from './ProfitCard';
import BarCharts from '../BarChart';
import LineCharts from '../LineChart';
import HotColdWallet from './HotColdWallet';
import StatsCard from '../StatsCard';
import FlashLoader from '../../../../components/CommonFunction/FlashLoader';

export default function CompanyBook() {
  const [companyDataConfigs, setCompanyDataConfigs] = useState({
    dataLoading: loadingStates.NO_ACTIVE_REQUEST,
    companyData: null,
  });
  const { colorScheme } = useMantineColorScheme();
  const [chartData, setChartData] = useState(null);
  const getCompanyData = async () => {
    setCompanyDataConfigs({
      ...companyDataConfigs,
      dataLoading: loadingStates.LOADING,
    });
    const resp = await apiWrapWithErrorWithData(V2companyBookAnalyticsApi());
    if (resp?.success) {
      setCompanyDataConfigs((prevValue) => ({
        ...prevValue,
        dataLoading: loadingStates.FINISHED,
        companyData: resp.data,
      }));
    } else {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Failed to load Company data.',
      });
    }
  };
  const fetchChartData = async () => {
    const resp = await apiWrapWithErrorWithData(V2companyBookChartsApi());

    if (resp?.success && resp?.data) {
      setChartData(resp.data);
    } else {
      showNotification({
        color: 'red',
        message: 'Couldn\'t load chart data',
        title: 'Error while fetching chart data',
      });
    }
  };

  useEffect(() => {
    fetchChartData();
    getCompanyData();
  }, []);
  const totalProgress = companyDataConfigs?.companyData?.statistics?.profitableTrades
    + companyDataConfigs?.companyData?.statistics?.losingTrades
    + companyDataConfigs?.companyData?.statistics?.openTrades;
  const statsData = [
    {
      label: 'Total Trades',
      value: totalProgress,
      sections: [
        { value: (companyDataConfigs?.companyData?.statistics?.profitableTrades / totalProgress) * 100, color: 'green' },
        { value: (companyDataConfigs?.companyData?.statistics?.losingTrades / totalProgress) * 100, color: 'red' },
        { value: (companyDataConfigs?.companyData?.statistics?.openTrades / totalProgress) * 100, color: 'blue' },
      ],
    },
    {
      label: 'Profitable Trades',
      value: companyDataConfigs?.companyData?.statistics?.profitableTrades,
      sections: [{ value: (companyDataConfigs?.companyData?.statistics?.profitableTrades / totalProgress) * 100, color: 'green' }],
    },
    {
      label: 'Losing Trades',
      value: companyDataConfigs?.companyData?.statistics?.losingTrades,
      sections: [{ value: (companyDataConfigs?.companyData?.statistics?.losingTrades / totalProgress) * 100, color: 'red' }],
    },
    {
      label: 'Open Trades',
      value: companyDataConfigs?.companyData?.statistics?.openTrades,
      sections: [{ value: (companyDataConfigs?.companyData?.statistics?.openTrades / totalProgress) * 100, color: 'blue' }],
    }];
  return (
    <div>
      <div className="p-12">
        {companyDataConfigs.dataLoading === loadingStates.LOADING && (
          <div className={`h-[40rem] bg-white ${colorScheme === 'dark' ? 'opacity-40' : 'opacity-100'}`}>
            <FlashLoader />
          </div>
        )}
        {companyDataConfigs.dataLoading === loadingStates.FINISHED && (
          <div className={styles.companyDataLayout}>

            {/* First row */}

            <div className={styles.pnlCard}>
              <LineCharts
                chartName="Profit / Loss"
                total={chartData?.pnlOneMonth?.total}
                changeInPercentage={chartData?.pnlOneMonth?.changeInPercentage}
                days={30}
                data={chartData?.pnlOneMonthData}
              />
            </div>
            <div className={styles.winRate}>
              <LineCharts
                chartName="Profit / Loss"
                total={chartData?.pnlSixMonths?.total}
                changeInPercentage={chartData?.pnlSixMonths?.changeInPercentage}
                days={180}
                data={chartData?.pnlSixMonthsData}
              />
            </div>
            <div className={styles.valRealizedProfit}>
              <div className="grid h-full w-full justify-center items-center p-8">
                <span className={`${styles.valueText} text-green-500`}>{renderNumber(companyDataConfigs?.companyData?.realizedProfit, true)}</span>
                <span className={`${styles.Text}`}>Realized Profit</span>
              </div>
            </div>
            <div className={styles.valRunningPnL}>
              <div className="grid h-full w-full justify-center items-center p-8">
                <span className={`${styles.valueText} ${companyDataConfigs?.companyData?.lifetimeData?.runningPnL > 0 ? 'text-green-500' : 'text-red-500'}`}>{renderNumber(companyDataConfigs?.companyData?.runningPnl, true)}</span>
                <span className={`${styles.Text}`}>Running P/L</span>
              </div>
            </div>

            {/* 2nd row */}

            <div className={styles.pnlCard2}>
              <BarCharts
                data={chartData?.winRateSixMonthsData}
                chartName="Win Rate"
                average={chartData?.winRateSixMonths?.average}
                days="Last 6 Months"
                color={['#0093EF', '#E9EDF7']}
              />
            </div>
            <div className={styles.roiCard}>
              <BarCharts
                data={chartData?.roiSixMonthsData}
                chartName=" ROI"
                average={chartData?.roiSixMonths?.average}
                days="Last 6 Months"
                color={['#F9A61A', '#F8994238']}
              />
            </div>
            <div className={styles.valOpenPost}>
              <div className="grid h-full w-full justify-center items-center p-8">
                <span className={styles.valueText}>{renderNumber(companyDataConfigs?.companyData?.openPositionsValue, true)}</span>
                <span className={`${styles.Text}`}>Value of Open Positions</span>
              </div>
            </div>
            <div className={styles.valTotalInvest}>
              <div className="grid h-full w-full justify-center items-center p-8">
                <span className={styles.valueText}>{renderNumber(companyDataConfigs?.companyData?.totalInvestmentValue, true)}</span>
                <span className={`${styles.Text}`}>Total Investments</span>
              </div>
            </div>

            {/* 3rd row */}

            <div className={styles.hotColdWallet}>
              <HotColdWallet data={companyDataConfigs.companyData.walletData} />
            </div>
            <div className={styles.statsCard}>
              <StatsCard
                data={statsData}
                title="Stats"
              />
            </div>
            <div className={styles.profitsCard}><ProfitCard data={companyDataConfigs?.companyData?.lifetimeProfit} /></div>
            <div className={styles.valTotalFund}>
              <div className="grid h-full w-full justify-center items-center p-8">
                <span className={styles.valueText}>
                  {renderNumber(companyDataConfigs?.companyData?.totalFundsValue, true)}
                </span>
                <span className={`${styles.Text}`}>Total Funds</span>
              </div>
            </div>
            <div className={styles.valTotalCurrVal}>
              <div className="grid h-full w-full justify-center items-center p-8">
                <span className={styles.valueText}>
                  {renderNumber(companyDataConfigs?.companyData?.totalCurrentValue, true)}
                </span>
                <span className={`${styles.Text}`}>Total Current Value</span>
              </div>
            </div>
          </div>
        )}

      </div>

    </div>
  );
}
